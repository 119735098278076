import React, {useState, useEffect} from "react";

export default function SoftshelljackePrimoBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M457.8,446.322c-1.29-19.91-3.089-43.419-6.549-67.049c-1.36-9.3-2.26-18.76-3.13-27.92
		c-0.47-4.84-0.941-9.84-1.48-14.771c-0.421-3.84-1.201-7.71-1.961-11.449c-0.449-2.211-0.91-4.49-1.289-6.73
		c-1.65-9.75-3.41-19.72-5.23-29.621c-1.13-6.12-2.221-12.28-3.27-18.239c-3.86-21.95-7.52-42.69-13.47-64.1
		c-6.621-23.8-15.491-49.29-24.34-69.93c-4.41-10.29-10.41-19.72-13.63-24.78c-1.31-2.061-2.441-3.83-2.481-4.161l-0.229,0.01
		l0.099-0.099c-0.889-0.911-0.969-0.97-1.29-1.13c-0.159-0.08-0.389-0.2-0.899-0.55c-24.28-16.53-78.971-32.74-83.351-34.021
		c0.31-2.069,1.841-14.069-3.78-19.21c-6.309-5.759-20.92-11.729-39.09-12.259v-0.01c-0.809-0.021-1.619-0.03-2.41-0.03
		c-0.769,0-1.55,0.009-2.33,0.03v0.01c-18.17,0.53-32.779,6.5-39.1,12.259c-5.62,5.141-4.089,17.141-3.779,19.21
		c-4.381,1.281-59.07,17.491-83.35,34.021c-0.51,0.35-0.741,0.47-0.89,0.55c-0.33,0.16-0.41,0.219-1.29,1.13l0.09,0.089h-0.22
		c-0.051,0.331-1.181,2.1-2.48,4.161c-3.231,5.06-9.231,14.49-13.64,24.78c-8.841,20.64-17.71,46.13-24.33,69.93
		c-5.96,21.41-9.611,42.129-13.481,64.08c-1.049,5.969-2.13,12.139-3.259,18.259c-1.831,9.92-3.59,19.89-5.241,29.621
		c-0.38,2.24-0.84,4.53-1.28,6.73c-0.76,3.739-1.539,7.609-1.959,11.449c-0.541,4.92-1.02,9.92-1.48,14.75
		c-0.871,9.16-1.781,18.641-3.141,27.941c-3.449,23.63-5.25,47.139-6.549,67.049c-0.14,0.111-0.23,0.281-0.25,0.481
		c-0.02,0.32,0.159,0.6,0.44,0.699c20.04,6.92,33.719,8.78,42.65,8.78c4.59,0,7.93-0.489,10.229-1.04
		c0.031-0.019,0.81-0.4,1.14-1.41c0.09-0.479,8.08-48.139,10.961-56.189c2.44-6.801,18.73-66.95,20.819-78.5
		c1.351-7.37,5.691-22.92,9.88-37.96c0.631-2.24,1.25-4.47,1.87-6.681c3.67,44.731,6.96,89.621,6.42,101.291
		c-1.149,25.01-1.319,56.049-1.329,56.36c0.01,0.839,0.719,20.72,49.07,33.86c13.83,2.8,28.379,4.28,44.469,4.53
		c3.961,0.06,7.811,0.1,11.59,0.1c20.07,0,37.791-1.01,55.66-4.63c48.321-13.14,49.031-33.021,49.04-33.86
		c0-0.311-0.17-31.35-1.329-56.36c-0.531-11.67,2.75-56.56,6.43-101.291c0.609,2.2,1.229,4.41,1.85,6.651
		c4.2,15.049,8.549,30.61,9.89,37.99c2.1,11.55,18.39,71.699,20.819,78.5c2.88,8.05,10.88,55.71,10.97,56.24
		c0.321,0.959,1.101,1.34,1.2,1.38c2.271,0.54,5.58,1.03,10.14,1.03c8.91,0,22.61-1.86,42.681-8.791
		c0.28-0.099,0.45-0.379,0.429-0.699C458.041,446.603,457.94,446.433,457.8,446.322z"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M208.862,55.759
		c4.173-3.806,18.842-12.357,39.152-12.817l0.396,0.001c0.545-0.01,1.062-0.02,1.579-0.02l2,0.02
		c20.227,0.468,35.091,8.736,39.262,12.539"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
		M350.316,426.887c-2.895,6.172-12.704,18.53-45.813,27.549c-15.803,3.193-32.242,4.555-54.95,4.555
		c-3.585,0-7.391-0.032-11.636-0.098c-15.946-0.248-30.31-1.706-43.91-4.457c-34.418-9.373-43.665-22.381-46.133-28.254"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
		M351.514,423.094c-0.022,0.249-0.192,1.647-1.198,3.793"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M94.389,444.481
		c-2.521,0.532-5.49,0.802-8.839,0.802c-10.76,0-24.262-2.719-40.155-8.084"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
		M405.419,444.481c2.521,0.532,5.49,0.802,8.839,0.802c10.76,0,24.262-2.719,40.155-8.084"
        />
        <path
          fill="#010101"
          d="M457.688,446.322c-1.29-19.91-3.089-43.419-6.549-67.049c-1.36-9.3-2.26-18.76-3.13-27.92
		c-0.47-4.84-0.941-9.84-1.48-14.771c-0.421-3.84-1.201-7.71-1.961-11.449c-0.449-2.211-0.91-4.49-1.289-6.73
		c-1.65-9.75-3.41-19.72-5.23-29.621c-1.13-6.12-2.221-12.28-3.27-18.239c-3.86-21.95-7.52-42.69-13.47-64.1
		c-6.621-23.8-15.491-49.29-24.34-69.93c-4.41-10.29-10.41-19.72-13.63-24.78c-1.31-2.061-2.441-3.83-2.481-4.161l-0.229,0.01
		l0.099-0.099c-0.889-0.911-0.969-0.97-1.29-1.13c-0.159-0.08-0.389-0.2-0.899-0.55c-24.28-16.53-78.971-32.74-83.351-34.021
		c0.31-2.069,1.841-14.069-3.78-19.21c-6.309-5.759-20.92-11.729-39.09-12.259v-0.01c-0.809-0.021-1.619-0.03-2.41-0.03
		c-0.769,0-1.55,0.009-2.33,0.03v0.01c-18.17,0.53-32.779,6.5-39.1,12.259c-5.62,5.141-4.089,17.141-3.779,19.21
		c-4.381,1.281-59.07,17.491-83.35,34.021c-0.51,0.35-0.741,0.47-0.89,0.55c-0.33,0.16-0.41,0.219-1.29,1.13l0.09,0.089h-0.22
		c-0.051,0.331-1.181,2.1-2.48,4.161c-3.231,5.06-9.231,14.49-13.64,24.78c-8.841,20.64-17.71,46.13-24.33,69.93
		c-5.96,21.41-9.611,42.129-13.481,64.08c-1.049,5.969-2.13,12.139-3.259,18.259c-1.831,9.92-3.59,19.89-5.241,29.621
		c-0.38,2.24-0.84,4.53-1.28,6.73c-0.76,3.739-1.539,7.609-1.959,11.449c-0.541,4.92-1.02,9.92-1.48,14.75
		c-0.871,9.16-1.781,18.641-3.141,27.941c-3.449,23.63-5.25,47.139-6.549,67.049c-0.14,0.111-0.23,0.281-0.25,0.481
		c-0.02,0.32,0.159,0.6,0.44,0.699c20.04,6.92,33.719,8.78,42.65,8.78c4.59,0,7.93-0.489,10.229-1.04
		c0.031-0.019,0.81-0.4,1.14-1.41c0.09-0.479,8.08-48.139,10.961-56.189c2.44-6.801,18.73-66.95,20.819-78.5
		c1.351-7.37,5.691-22.92,9.88-37.96c0.631-2.24,1.25-4.47,1.87-6.681c3.67,44.731,6.96,89.621,6.42,101.291
		c-1.149,25.01-1.319,56.049-1.329,56.36c0.01,0.839,0.719,20.72,49.07,33.86c13.83,2.8,28.379,4.28,44.469,4.53
		c3.961,0.06,7.811,0.1,11.59,0.1c20.07,0,37.791-1.01,55.66-4.63c48.321-13.14,49.031-33.021,49.04-33.86
		c0-0.311-0.17-31.35-1.329-56.36c-0.531-11.67,2.75-56.56,6.43-101.291c0.609,2.2,1.229,4.41,1.85,6.651
		c4.2,15.049,8.549,30.61,9.89,37.99c2.1,11.55,18.39,71.699,20.819,78.5c2.88,8.05,10.88,55.71,10.97,56.24
		c0.321,0.959,1.101,1.34,1.2,1.38c2.271,0.54,5.58,1.03,10.14,1.03c8.91,0,22.61-1.86,42.681-8.791
		c0.28-0.099,0.45-0.379,0.429-0.699C457.929,446.603,457.828,446.433,457.688,446.322 M208.919,53.053
		c4.689-4.28,19.159-12.11,41.079-12.131c0.471,0,0.94,0.011,1.41,0.011c0.16,0.01,0.33,0.01,0.5,0.01
		c20.801,0.469,34.521,7.96,39.061,12.11c5.269,4.809,3.88,16.49,3.58,18.58c-2.93-0.561-24.58-4.561-44.941-4.381
		c-20.149-0.08-41.37,3.83-44.269,4.381C205.039,69.543,203.648,57.862,208.919,53.053 M137.449,281.013
		c-4.201,15.05-8.551,30.609-9.89,38.01c-2.1,11.54-18.371,71.61-20.8,78.399c-2.901,8.11-10.91,55.82-10.97,56.261
		c-0.23,0.659-0.74,0.94-0.74,0.95c-6.63,1.58-22.26,2.68-52.441-7.74c-0.009-0.01-0.009-0.061,0-0.08
		c0,0.009-0.019,0.019-0.03,0.019l-0.06-0.319l0.321,0.019c1.3-19.929,3.09-43.5,6.55-67.159c1.37-9.33,2.269-18.81,3.15-27.98
		c0.459-4.83,0.93-9.821,1.47-14.74c0.42-3.81,1.2-7.661,1.95-11.39c0.449-2.21,0.91-4.5,1.3-6.761
		c1.639-9.72,3.399-19.68,5.229-29.599c1.131-6.13,2.211-12.3,3.271-18.27c3.86-21.931,7.509-42.641,13.45-64.021
		c6.619-23.769,15.47-49.229,24.309-69.839c4.39-10.25,10.371-19.651,13.59-24.701c1.21-1.9,1.91-3.019,2.281-3.7
		c0.939,4.931,6.58,34.441,11.769,65.49c0.931,10.121,4.941,54.121,8.621,98.771C139.018,275.383,138.238,278.193,137.449,281.013
		 M359.789,268.662c-3.86,46.601-7.461,94.961-6.9,107.16c1.15,25,1.32,56.02,1.32,56.331c0,0.2-0.79,20.24-48.54,33.23
		c-21.151,4.27-42.11,4.899-67.091,4.51c-16.049-0.25-30.56-1.721-44.33-4.51c-47.779-12.99-48.56-33.03-48.569-33.23
		c0-0.311,0.179-31.331,1.33-56.331c1.16-25.029-15.241-202.4-15.401-204.189l-0.159,0.02c-5.381-31.97-11.11-61.781-11.541-64
		c0.58-0.59,0.631-0.621,0.851-0.731c0.16-0.079,0.41-0.209,0.96-0.579c25.04-17.061,82.67-33.79,83.429-34.011
		c0.521-0.109,21.701-4.269,42.441-4.439l0.01,0.04c0.799-0.021,1.61-0.03,2.41-0.04c0.77,0.01,1.53,0.019,2.29,0.04v-0.04
		c20.74,0.17,41.929,4.33,42.439,4.439c0.771,0.221,58.391,16.95,83.441,34.011c0.54,0.37,0.79,0.5,0.96,0.579
		c0.21,0.11,0.259,0.141,0.849,0.731c-0.429,2.219-6.17,32.03-11.539,64l-0.16-0.02c-0.01,0.109-0.08,0.85-0.2,2.13
		C367.199,183.523,363.379,225.332,359.789,268.662 M457.289,446.883c-30.181,10.43-45.82,9.33-52.381,7.77
		c-0.029-0.01-0.569-0.28-0.789-0.92c-0.08-0.491-8.08-48.201-10.99-56.311c-2.43-6.789-18.691-66.859-20.79-78.399
		c-1.351-7.411-5.7-22.98-9.901-38.05c-0.79-2.811-1.569-5.611-2.33-8.34c3.681-44.66,7.701-88.681,8.63-98.79
		c5.191-31.04,10.821-60.54,11.771-65.471c0.36,0.681,1.069,1.8,2.28,3.7c3.209,5.05,9.199,14.451,13.59,24.701
		c8.83,20.61,17.689,46.07,24.3,69.839c5.95,21.39,9.6,42.111,13.46,64.041c1.049,5.959,2.14,12.129,3.269,18.25
		c1.82,9.9,3.58,19.86,5.231,29.599c0.379,2.261,0.839,4.551,1.29,6.761c0.75,3.72,1.53,7.58,1.95,11.39
		c0.54,4.919,1.019,9.929,1.479,14.769c0.87,9.16,1.781,18.631,3.14,27.951c3.461,23.67,5.261,47.23,6.561,67.159l0.299-0.019
		l-0.079,0.3C457.299,446.832,457.289,446.883,457.289,446.883"
        />
      </g>
    </svg>
  );
}
